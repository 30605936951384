import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { reviewsList } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import about from '../img/about.jpg';

function AboutUs() {
	return (
		<>
			<Helmet>
				<title>About &bull; Kamp Christy &bull; The Cabin Life</title>
			</Helmet>

			<HeaderPage
				pageName={'About us'}
				subtitle={'our little story'}
				icon={<FontAwesomeIcon icon={solid('store')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section>
					<h2 className="txt-center">The Cabin Life</h2>
					<h3 className="subtitle txt-center mb-2">Gull Lake MN</h3>

					<p className="mb-2">Looking for the perfect cabin experience? Look no further! Our cozy family cabin on Upper Gull Lake has everything you need for a relaxing getaway. And with a private beach, amazing location and views, and the friendly atmosphere, you won't find a better spot for your next vacation.</p>
					<p className="mb-2">On the premier chain of lakes in Minnesota, the Gull Lake Chain, you can enjoy the lake, the shore, the cabin comfort, inside and out, in this true retreat.</p>
					<p className="mb-2">Our bright cabin has been refreshed throughout, and is fully staged for your comfort. Classic and historic, with modern comforts. Whether you're looking for a weekend getaway with a small group, or an extended family vacation, we have plenty of space for everyone. And with 2 docks available, you can bring your own boat or rent one on the lake.</p>
					<p className="mb-2">It's not just the amenities that make our cabin special. It's the location. Situated on Upper Gull Lake, you'll have access to a beautiful private swimming area, perfect for your lily pad, floaties or paddle board. With 2 kayaks available for your use, you can explore the lake at your leisure.</p>
					<p className="mb-2">And that's not all. Our cabin also has a fire pit area and deck, perfect for those summer nights spent roasting marshmallows and telling stories. With plenty of space for yard games, you'll never be bored.</p>
					<p className="mb-2">Inside, our great room has accommodations for 10+ people, with a cozy indoor kitchen and table for 10. And with outdoor grill and table space, as well as bar stools available on the deck that would seat 10 as well, you'll have plenty of options for meals and entertaining.</p>
					<p className="mb-2">So what are you waiting for?  For the holidays or for family events book our cabin  for a short or long term rental.</p>

					<img src={about} className="mb-2 rounded" alt="Great Room Table" />

					<div className="grid">
						{reviewsList.map((review, idx) => 
							<Review key={idx}
								img={review.img}
								fullname={review.name}
								stars={review.stars}
								review={review.text}
							/>
						)}
					</div>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default AboutUs;