import './HomeNav.css';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import logo from '../img/KC-logo.svg';

function HomeNav() {
   const mainNav = useRef();

   const handleMove = e => {
      e.preventDefault();
      mainNav.current.scrollLeft += e.deltaY;
   }
   
   const visible = useVisible(15);

   return (
      <>
         <Helmet>
            <title>Kamp Christy - Gull Lake MN</title>
         </Helmet>

         <motion.header
            className="logo"

            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img src={logo} alt="KC Logo" />
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onWheel={handleMove}

            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/about-us'>
                        <section>
                           <h1>About Kamp Christy</h1>
                           <p className="badge">our little story</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('store')} />
                           <h3>The Cabin Life</h3>
                           <p>Lake Life</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/team'>
                        <section>
                           <h1>Team</h1>
                           <p className="badge">a great crew</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('users')} />
                           <h3>We are a family</h3>
                           <p>We love what we do</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/gallery'>
                        <section>
                           <h1>Gallery</h1>
                           <p className="badge">look here</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('eye')} />
                           <h3>See for yourself</h3>
                           <p>While we are at work</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/contact'>
                        <section>
                           <h1>Contact us</h1>
                           <p className="badge">leave a message</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('phone')} />
                           <h3>Get in Touch</h3>
                           <p>We will get back to you!</p>
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}

export default HomeNav;