import { motion } from 'framer-motion';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function VRBOBtn() {
   const styleBtn = {
      zIndex: 999999,
      position: 'fixed',
      top: '5.5rem',
      right: '-.8rem',
      borderRadius: '0',
      borderRight: '0',
      paddingRight: '2rem',
      fontSize: '1.3rem',
   };

   return (
      <motion.a
         href="https://www.vrbo.com/2995894?unitId=3567938"
         className="badge pulse"
         style={styleBtn}
         target="_blank"
         rel="noopener noreferrer"

         initial={{ opacity: 0, x: '100%' }}
         animate={{ opacity: 1, x: 0 }}
         transition={{ duration: .8, ease: 'easeIn' }}
      >
         <span style={{ marginLeft: '.5rem' }}>VRBO</span>
      </motion.a>
   );
}

export default VRBOBtn;