import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import AboutUs from './pages/AboutUs';
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';
import VRBOBtn from './components/VRBOBtn';


function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location])

	return (
		<AnimatePresence>
			<Routes location={location} key={location.key}>
				<Route path='/' element={<HomeNav />} />
				<Route path='about-us' element={<AboutUs />} />
				<Route path='team' element={<Team />} />
				<Route path='gallery' element={<Gallery />} />
				<Route path='contact' element={<Contact />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
			<VRBOBtn />
		</AnimatePresence>
	);
}

export default App;