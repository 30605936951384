import './Gallery.css';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { galleryImgs } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import LightboxModal from '../components/LightboxModal';
import Footer from '../components/Footer';

function Gallery() {
   const [clickedImg, setClickedImg] = useState(null),
         [currentIdx, setCurrentIdx] = useState(null),
         imgsLength = galleryImgs.length;

   const handleOpen = (img, idx) => {
      setClickedImg(img);
      setCurrentIdx(idx);
   };
   
   const handleNext = () => {
      if(currentIdx + 1 >= imgsLength) {
         setCurrentIdx(0);
         setClickedImg(galleryImgs[0]);
         return;
      }

      const newIdx = currentIdx + 1;
      setCurrentIdx(newIdx);
      setClickedImg(galleryImgs[newIdx]);
   };
  
   const handlePrev = () => {
      if(currentIdx === 0) {
         const newIdx = imgsLength - 1;
         setCurrentIdx(newIdx);
         setClickedImg(galleryImgs[newIdx]);
         return;
      }
      
      const newIdx = currentIdx - 1;
      setCurrentIdx(newIdx);
      setClickedImg(galleryImgs[newIdx]);
   };

   const handleClose = e => e.target.classList.contains('open') ? setClickedImg(null) : null;
  
   return (
      <>
         <Helmet>
            <title>Gallery &bull; Kamp Christy &bull; The Cabin Life</title>
         </Helmet>

         <HeaderPage
				pageName={'Gallery'}
				subtitle={'look here'}
				icon={<FontAwesomeIcon icon={solid('eye')} className="badge-icon" />}
			/>

			<main className="gallery-container">

            {galleryImgs.map((img, idx) =>
               <motion.img
                  key={idx}
                  src={img.src}
                  alt={img.alt}
                  onClick={()=>handleOpen(img, idx)}

                  initial={{ opacity: 0, y: '100%' }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: .8, ease: 'easeInOut' }}
                  exit={{ opacity: 0, y: '100%' }}
               />
            )}
            
            {clickedImg && (
               <LightboxModal
                  clickedImg={clickedImg}
                  handleClose={handleClose}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
               />
            )}
			</main>

			<Footer />
      </>
   );
}

export default Gallery;