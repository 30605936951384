// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// reviews images
import review1 from './img/review1.jpg';
import review2 from './img/review2.jpg';
// team images
import hostess from './img/hostess.jpg';
//import chef from './img/chef.jpg';
//import barman from './img/barman.jpg';
//import waiter from './img/waiter.jpg';
// gallery images
import gallery1 from './img/gallery/gallery1.jpg';
import gallery2 from './img/gallery/gallery2.jpg';
import gallery3 from './img/gallery/gallery3.jpg';
import gallery4 from './img/gallery/gallery4.jpg';
import gallery5 from './img/gallery/gallery5.jpg';
import gallery6 from './img/gallery/gallery6.jpg';
import gallery7 from './img/gallery/gallery7.jpg';
import gallery8 from './img/gallery/gallery8.jpg';
import gallery9 from './img/gallery/gallery9.jpg';
import gallery10 from './img/gallery/gallery10.jpg';
import gallery11 from './img/gallery/gallery11.jpg';
import gallery12 from './img/gallery/gallery12.jpg';


export const reviewsList = [
   {
      img: review1,
      name: 'Kiera M.',
      stars: 5,
      text: 'Wowwww! Staying at this place was just what I needed for a getaway. My friend and I stayed here and it was everything we hoped for. The bedding was super comfy and welcoming. The decor was very nice. And there’s a fire place and wood available which is nice if you come during the cold months. In the mornings we would drink coffee and look out at the lake and it was the best way to start the day. I wish I could move in! Kristy, the hostess, was incredibly friendly as well. Easy to talk to and very helpful! 5 stars!!! You won’t regret staying here.'
   },
   {
      img: review2,
      name: 'Joe P.',
      stars: 5,
      text: 'This cabin is so perfect, clean and nicely decorated. I can’t wait to book it again this summer! The lake views at this cabin are spectacular and the extra attention to detail in the cabin is so fun. Also the bed linens are luxurious. We plan to bring our whole Family back to enjoy this hidden gem...'
   }
];


export const teamMembers = [
   {
      img: hostess,
      role: 'Hostess',
      name: 'Kristy',
      text: 'I have a passion for working with people, design, and good times.  Vacation rental allows me to do all of that!  For 20+ years, I have been a realtor, builder, land developer, property manager, and hostess.  My team and I are back in the Gull Lake Chain/Whitefish Lake Chain area again and having a blast.  Let`s get acquainted and let the fun begin!',
      cite: '...Kristy is the epitome of a hostess, she made our week better than we could have hoped! - Kate M.'
   },
/*   {
      img: barman,
      role: 'Barman',
      name: 'Bruno Smith',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
      cite: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      img: waiter,
      role: 'Waiter',
      name: 'Jessica Smith',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.',
      cite: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   }, */
];


export const galleryImgs = [
   {
      src: gallery1,
      alt: 'Pic 1'
   },
   {
      src: gallery2,
      alt: 'Pic 2'
   },
   {
      src: gallery3,
      alt: 'Pic 3'
   },
   {
      src: gallery4,
      alt: 'Pic 4'
   },
   {
      src: gallery5,
      alt: 'Pic 5'
   },
   {
      src: gallery6,
      alt: 'Pic 6'
   },
   {
      src: gallery7,
      alt: 'Pic 7'
   },
   {
      src: gallery8,
      alt: 'Pic 8'
   },
   {
      src: gallery9,
      alt: 'Pic 9'
   },
   {
      src: gallery10,
      alt: 'Pic 10'
   },
   {
      src: gallery11,
      alt: 'Pic 11'
   },
   {
      src: gallery12,
      alt: 'Pic 12'
   }
];