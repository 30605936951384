import './Contact.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

function Contact() {
   return (
      <>
         <Helmet>
            <title>Contact Us &bull; Kamp Christy &bull; The Cabin Life</title>
         </Helmet>

			<HeaderPage
				pageName={'Contact us'}
				subtitle={'leave a message'}
				icon={<FontAwesomeIcon icon={solid('phone')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>
					<h2 className="txt-center">Contact information</h2>
					<h3 className="subtitle txt-center mb-2">Where we are</h3>

					<p className="mb-2">We are located on Upper Gull Lake in Lake Shore MN and are part of The Gull Chain of Lakes which is located only minutes from Brainerd, Minnesota and is just over 2 hours from Minneapolis. This chain consists of 8 lakes, connecting channels, and covers over 13,000 acres. The Gull Lake Chain is one of the state`s finest waters with many activities for all ages and interests. Boat, fish, swim, ski, board, sail, golf, dine, snowmobile…this 15 mile long lake is arguably, Minnesota`s premier lake.</p>

               <div className="grid">
                  <iframe className="map rounded" title="Maps" src="https://www.google.com/maps?q=Lakeshore+MN&t=&z=8&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />

                  <ul className="contact-info">
                     <li>
                        <FontAwesomeIcon icon={solid('V')} />
                        <a href="https://www.vrbo.com/2995894?unitId=3567938">Connect with us on VRBO</a>
                     </li>
                     <li>
                        <FontAwesomeIcon icon={brands('airbnb')} />
                        <a href="https://airbnb.com/h/kampchristy">Connect with us on Airbnb</a>
                     </li>
                  </ul>
               </div>
				</section>

            <section>
					<h2 className="txt-center">Rental Calendar</h2>
					<h3 className="subtitle txt-center mb-2">Come visit</h3>
               <div className="grid">
                  <p>Our rental season is the last half of May until Oct 1.  However, please feel free to inquire about any other time.</p>

                  <div className="hours-time">
                     <time>
                        <p className="subtitle">May</p>
                        <h3>15th-31st</h3>
                     </time>
                     <time>
                        <p className="subtitle">June</p>
                        <h3>All month</h3>
                     </time>
                     <time>
                        <p className="subtitle">July</p>
                        <h3>All month</h3>
                     </time>
                     <time>
                        <p className="subtitle">August</p>
                        <h3>All month</h3>
                     </time>
                     <time>
                        <p className="subtitle">September</p>
                        <h3>All month</h3>
                     </time>
                  </div>
               </div>
				</section>

            <section className="txt-center">
					<h2>Get in Touch</h2>
					<h3 className="subtitle mb-2">We will get back to you!</h3>
					<p className="mb-2">Leave a note with some detail as to your inquiry.  We strive to respond within a few hours.</p>

               <ContactForm />
				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default Contact;