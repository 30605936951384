import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import logo from '../img/KC-logo.svg';

function Footer() {
   const thisYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         
         <img src={logo} className="mb-2" height="90" alt="Kamp Christy Logo" />

         <nav className="mb-2">
            <ul className="inline">
               <li><Link to ='/'>Home</Link></li>
               <li><Link to='/about-us'>Kamp Christy</Link></li>
				   <li><Link to='/team'>Team</Link></li>
               <li><Link to='/gallery'>Gallery</Link></li>
               <li><Link to ='/contact'>Contact us</Link></li>
            </ul>
         </nav>

         <p>&copy; Kamp Christy {thisYear}. All Rights Reserved.</p>
         <p>Developed by <a href="Mars-Tek.com" target="_blank" rel="noopener noreferrer">Mars-Tek</a>.</p>
      </motion.footer>
   );
}

export default Footer;