import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { teamMembers } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Footer from '../components/Footer';

function Team() {
	return (
		<>
			<Helmet>
				<title>Team &bull; Kamp Christy &bull; The Cabin Life</title>
			</Helmet>

			<HeaderPage
				pageName={'Team'}
				subtitle={'a strong union'}
				icon={<FontAwesomeIcon icon={solid('users')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>We take having fun seriously</h2>
					<h3 className="subtitle mb-2">Linked by passion</h3>

					<p className="mb-2"></p>

					{teamMembers.map((member, idx) =>
						<article className="grid txt-left" key={idx}>
							<img src={member.img} className="rounded" alt={member.name} />
							<div>
								<h3 className="subtitle">{member.role}</h3>
								<h2 className="mb-2">{member.name}</h2>
								<p className="mb-2">{member.text}</p>
								<p className="italic">" {member.cite} "</p>
							</div>
						</article>
					)}
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default Team;